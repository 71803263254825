/* eslint-disable react/no-unused-state */
import { autobind } from 'core-decorators';
import debounce from 'lodash.debounce';
import React, { PureComponent } from 'react';
import OrderStatusEnum from 'const/OrderStatusEnum';
import { processing } from 'decorators';
import * as mainApi from 'services/api/main';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type State = {
  pendingOrders: WoaRequest[];
  accountId?: number;
  isFetching: boolean;
  keyword?: string;
  error?: Error;
  numOfResults?: number;
  offset: number;
  totalOrders: number;
  getPendingOrdersList: (accountId: number, params?: { keyword?: string }, offset?: number) => void;
};

type PendingOrdersContextInterface = State & {
  reloadPendingOrders: () => void;
  updateOrdersList: () => void;
};

const defaultValue = {
  pendingOrders: [],
  isFetching: true,
  totalOrders: 0,
  offset: 0,
  getPendingOrdersList: () => {},
  reloadPendingOrders: () => {},
  updateOrdersList: () => {},
};

const PendingOrdersContext = React.createContext<PendingOrdersContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class PendingOrdersProviderWrapper extends PureComponent<any, State> {
    state: State = {
      pendingOrders: [],
      isFetching: true,
      totalOrders: 0,
      numOfResults: 15,
      offset: 0,
      getPendingOrdersList: debounce((accountId, params) => this._getPendingOrdersList(accountId, params), 100),
    };

    @withErrorNotification
    @processing('isFetching')
    async _getPendingOrdersList(accountId, { keyword }: { keyword?: string } = {}) {
      let { offset } = this.state;
      if (this.state.keyword !== keyword) {
        offset = 0;
      }
      this.setState({ accountId, keyword, numOfResults: 15, offset });
      try {
        const [pendingOrders, totalOrders] = await Promise.all([
          mainApi.getPendingOrders(accountId, keyword, this.state.numOfResults, this.state.offset),
          mainApi.getRequestCount(accountId, OrderStatusEnum.PENDING),
        ]);

        this.setState(state => ({
          error: null,
          ...state,
          pendingOrders: offset === 0 ? pendingOrders : state.pendingOrders.concat(pendingOrders),
          totalOrders: totalOrders > 0 ? totalOrders : 0, //Prevent totalOrders being -1,
        }));
      } catch (error) {
        this.setState({ error, pendingOrders: [] });

        throw error;
      }
    }

    reloadPendingOrders() {
      const { accountId, getPendingOrdersList, keyword } = this.state;

      if (accountId) {
        getPendingOrdersList(accountId, { keyword });
      }
    }

    updateOrdersList() {
      if (this.state.totalOrders > this.state.offset + this.state.numOfResults) {
        this.setState(state => ({ ...state, offset: state.offset + state.numOfResults }));
      }

      this.reloadPendingOrders();
    }

    render() {
      return (
        <PendingOrdersContext.Provider
          value={{
            ...this.state,
            reloadPendingOrders: this.reloadPendingOrders,
            updateOrdersList: this.updateOrdersList,
          }}
        >
          <WrappedComponent {...this.props} />
        </PendingOrdersContext.Provider>
      );
    }
  }

  return PendingOrdersProviderWrapper;
}

export { PendingOrdersContextInterface };
export default {
  useContext: () => React.useContext(PendingOrdersContext),
  withProvider,
  withContext: mainWithContext(PendingOrdersContext),
};
