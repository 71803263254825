import React, { useState, useEffect } from 'react';
import Elements from 'components/Elements';
import ErrorBaner from 'components/ErrorBaner';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import OrdersList from 'components/OrdersList';
import PendingOrders from 'components/PendingOrders';
import RequestReportModal from 'components/RequestReportModal/RequestReportModal';
import Search from 'components/Search/Search';
import SearchBanner from 'components/SearchBanner/SearchBanner';
import AccountContext from 'storage/AccountContext';
import { Emitter as NotesEmitter } from 'storage/ArticleNoteContext';
import { Emitter } from 'storage/OrderActionsContext';
import OrdersContext from 'storage/OrdersContext';

function OrdersFilter() {
  const accountContext = AccountContext.useContext();
  const { selectedAccount } = accountContext;

  const {
    error,
    isFetching,
    isReportSending,
    keyword,
    ordersList,
    updateOrdersList,
    getOrdersList,
    reloadOrders,
    totalOrders,
  } = OrdersContext.useContext();

  const [showAll, setShowAll] = useState(false);
  const [requestReport, setRequestReport] = useState(false);

  useEffect(() => {
    const subscriptions = [
      Emitter.ORDER_WAS_UPDATED.subscribe(reloadOrders),
      NotesEmitter.NOTE_WAS_ADDED.subscribe(reloadOrders),
      NotesEmitter.NOTE_WAS_UPDATED.subscribe(reloadOrders),
      NotesEmitter.NOTE_WAS_REMOVED.subscribe(reloadOrders),
    ];

    return () => subscriptions.forEach(x => x());
  }, []);

  useEffect(() => {
    getOrdersList(selectedAccount.id, { keyword, showAll });
  }, [selectedAccount.id]);

  const startSearch = value => {
    getOrdersList(selectedAccount.id, { keyword: value, showAll });
  };

  const clearSearch = () => {
    getOrdersList(selectedAccount.id, { keyword: undefined, showAll });
  };

  const onChangeShowAll = event => {
    setShowAll(event.target.checked);
    getOrdersList(selectedAccount.id, { keyword, showAll: event.target.checked });
  };

  return (
    <>
      <PendingOrders />

      {requestReport && <RequestReportModal close={() => setRequestReport(false)} />}

      <LoaderLayout className="w_100p" isLoading={isFetching}>
        <div className="ordersFilter-options">
          <div className="ordersFilter-showAll mr_10">
            <Elements.Checkbox checked={showAll} onChange={onChangeShowAll} seleniumid="ordersFilter-showAllHistory">
              show all request history
            </Elements.Checkbox>
          </div>
          <Search
            buttonSeleniumId="ordersFilter-search"
            inputClassName="ordersFilter-SearchInput"
            inputSeleniumId="ordersFilter-searchInput"
            isSearching={isFetching}
            keyword={keyword}
            onSubmit={startSearch}
            placeholder="Author, article title, DOI, journal or manuscript ID"
          />
        </div>

        <SearchBanner
          buttonSeleniumId="ordersFilter-clearSearchResult"
          isVisible={!!keyword}
          labelSeleniumId="ordersFilter-searchResult"
          onClear={clearSearch}
          text={`${isFetching ? '?' : ordersList.length} Results for your search for "${keyword}"`}
        />

        <h4 className="flex_v_center">
          {`${showAll ? 'All History' : 'Approved Requests'} (${ordersList.length})`}
          <button
            className="btn btn-secondary ml_10"
            data-seleniumid="ordersFilter-requestReport"
            disabled={ordersList.length === 0 || isReportSending}
            onClick={() => setRequestReport(true)}
            type="button"
          >
            Request Report
          </button>
        </h4>

        <OrdersList orders={ordersList} totalOrders={totalOrders} updateOrdersList={updateOrdersList} />

        <ErrorBaner error={error} />
      </LoaderLayout>
    </>
  );
}

export default OrdersFilter;
