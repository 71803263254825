import queryString from 'query-string';
import OrderStatusEnum from 'const/OrderStatusEnum';
import { get, post, put } from 'services/ajax';
import { fixWoaArticle, fixWoaEvent, fixWoaRequest } from 'services/api/utils';

const baseUrl = `${process.env.API_URL}/resource/v1`;

export function getOrderDocumentUrlSync(id) {
  return `${process.env.API_URL}/print/invoice/${id}`;
}

export async function requestOrdersReport(
  accountId: number,
  approvalStatuses: string,
  dateFrom?: string,
  dateTo?: string,
  keyword?: string,
): Promise<void> {
  const query = {
    accountId,
    approvalStatuses,
    dateFrom: dateFrom || undefined,
    dateTo: dateTo || undefined,
    keyword: keyword || undefined,
  };
  return get(`${process.env.API_URL}/report/articleHistory?${queryString.stringify(query)}`);
}

export async function getOrders(
  accountId: number,
  keyword: string,
  statuses: string,
  numOfResults?: number,
  offset?: number,
): Promise<WoaRequest[]> {
  const result = await get(`${baseUrl}/request`, {
    accountId,
    keyword,
    statuses,
    numOfResults,
    offset,
    resultData: 'WOA_REQUEST,WOA_NOTE,WOA_ARTICLE,WOA_UI_DATA,WOA_ACCOUNT',
  });
  return (result.woarequests || []).map(fixWoaRequest);
}

export async function getRequestCount(accountId?: number, statuses?: string): Promise<number> {
  return await get(`${baseUrl}/request/count`, { accountId, statuses });
}

export async function getPendingOrders(
  accountId: number,
  keyword: string,
  numOfResults?: number,
  offset?: number,
): Promise<WoaRequest[]> {
  return getOrders(accountId, keyword, OrderStatusEnum.PENDING, numOfResults, offset);
}

export async function denyOrder(orderId: number, params: { reason: string }): Promise<WoaRequest> {
  const result = await put(`${baseUrl}/request/${orderId}/deny?${queryString.stringify(params)}`);
  return fixWoaRequest(result.woarequest);
}

export async function approveOrder(orderId: number): Promise<WoaRequest> {
  const result = await put(`${baseUrl}/request/${orderId}/approve`);
  return fixWoaRequest(result.woarequest);
}

export async function getOrderDetails(orderId: number): Promise<WoaRequest> {
  const result = await get(`${baseUrl}/request/${orderId}`, {
    resultData: 'WOA_REQUEST,WOA_ARTICLE,WOA_NOTE,WOA_RESEARCH_FUNDERS,WOA_UI_DATA',
  });
  return fixWoaRequest(result.woarequest);
}

export async function getConfig(): Promise<{
  adminAccountsUrl: string;
  loginUrl: string;
  profileUrl: string;
}> {
  return get(`${baseUrl}/uiconfiguration`);
}

export async function getEvents(accountId: number): Promise<WoaEvent[]> {
  const { woaevents } = await get(`${baseUrl}/event`, {
    accountId,
    eventTypes: 'ARTICLE_PUB_STATUS_CHANGED',
    resultData: 'WOA_ARTICLE,WOA_UI_DATA',
    sinceLastNotificationCheck: true,
  });

  return (woaevents || []).map(fixWoaEvent);
}

export async function clearEvents(accountId: number): Promise<void> {
  await post(`${baseUrl}/user/lastnotificationchecktime?accountId=${accountId}`);
}

export async function addArticleNote(
  accountId: number,
  articleId: string,
  isInternal: boolean,
  note: string,
): Promise<WoaNote> {
  const result = await post(`${baseUrl}/note`, {
    woanote: { accountId, articleId, isInternal, note, isPublic: true },
  });
  return result.woanote;
}

export async function editArticleNote(noteId: number, note: string): Promise<WoaNote> {
  const result = await put(`${baseUrl}/note/${noteId}`, {
    woanote: { note },
  });
  return result.woanote;
}

export async function removeArticleNote(noteId: number): Promise<void> {
  await post(`${baseUrl}/note/${noteId}`, {});
}

export async function getNotificationConfig(
  userEmail: string,
  accountId: number,
): Promise<{
  user: UserWoaConfiguration;
  default: DefaultWoaConfiguration;
  group: GroupWoaConfiguration;
}> {
  const { woaconfigurations = [] } = await get(`${baseUrl}/configuration`, {
    userEmail,
    accountId,
  });

  return {
    user: woaconfigurations.find(el => !!el.userEmail && !!el.accountId),
    default: woaconfigurations.find(el => !!el.userEmail && !el.accountId),
    group: woaconfigurations.find(el => !el.userEmail && !!el.accountId),
  };
}

export async function saveNotificationConfig(
  userEmail: string,
  accountId: number,
  user: WoaConfiguration['setting'],
  group: WoaConfiguration['setting'],
) {
  await put(`${baseUrl}/configuration`, {
    woaconfigurations: [
      {
        accountId,
        userEmail,
        setting: user,
      },
      {
        accountId,
        setting: group,
      },
    ],
  });
}

export async function getBackendVersion(): Promise<string> {
  return (await get(`${process.env.API_URL}/buildversion`)).buildVersion;
}

export async function getArticle(articleId: number): Promise<WoaArticle> {
  return fixWoaArticle((await get(`${baseUrl}/article/${articleId}`)).woaarticle);
}
